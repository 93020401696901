import { Language, Registry } from '@lightningjs/sdk';
import { ACTIVATION_REFERRER, ACTIVATION_TYPE } from './constants';
import Router from '@lightningjs/sdk/src/Router';
import { EVENTS } from '../../../lib/analytics/types';
import { PROGRAMMING_TYPES, REGISTRATION_REFERRERS } from '../../../constants';
import { ModalTypes } from '../../../lib/ModalManager';
import { getUserProfile } from '../../../api/Identity';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { getCurrentModalInstance } from '..';
import { NOTIFICATION_TIMEOUT } from './strategies/ActivationStrategy';
const referrerMap = (programmingType) => ({
    [ACTIVATION_REFERRER.LINEAR]: REGISTRATION_REFERRERS.LIVE,
    [ACTIVATION_REFERRER.TEMPPASS]: REGISTRATION_REFERRERS.TEMP_PASS,
    [ACTIVATION_REFERRER.VOD]: REGISTRATION_REFERRERS.VOD,
    [ACTIVATION_REFERRER.CONTENT_GATING]: REGISTRATION_REFERRERS.VOD,
    [ACTIVATION_REFERRER.EVENT]: programmingType === PROGRAMMING_TYPES.FER
        ? REGISTRATION_REFERRERS.MVPD
        : REGISTRATION_REFERRERS.LIVE,
});
const dispatchActivationAnalytics = (activationType, context) => {
    const name = activationType === ACTIVATION_TYPE.NBC ? EVENTS.AUTH_SUCCESS_NBC : EVENTS.AUTH_SUCCESS_MVPD;
    const metadata = {
        path: ModalTypes.ACTIVATION,
        userProfile: getUserProfile(),
    };
    if (context) {
        const program = context._program;
        const id = context._streamId || (program === null || program === void 0 ? void 0 : program.mpxGuid);
        const referrerType = context._referrerType;
        metadata.program = program;
        if (id) {
            if (referrerType === ACTIVATION_REFERRER.VOD)
                metadata.videoId = id;
            else
                metadata.pid = id;
        }
        metadata.registrationReferrer =
            referrerMap(program === null || program === void 0 ? void 0 : program.programmingType)[referrerType] || REGISTRATION_REFERRERS.LIVE;
    }
    sendMetric(name, metadata);
};
export const notifyUser = async (activationType) => {
    const page = Router.getActivePage();
    const notificationWidget = page === null || page === void 0 ? void 0 : page.fireAncestors('$getWidget', 'Notification');
    if (notificationWidget) {
        notificationWidget._setNotificationMsg('images/check.png', true, Language.translate(activationType === ACTIVATION_TYPE.MVPD ? 'tv_provider_linked' : 'nbc_profile_signed_in'));
        notificationWidget.visible = true;
        const context = getCurrentModalInstance();
        // begin timer to hide, at the call callback function if provided
        const timeout = Registry.setTimeout(() => {
            if (timeout)
                Registry.clearTimeout(timeout);
            notificationWidget.visible = false;
            dispatchActivationAnalytics(activationType, context);
            Promise.resolve();
        }, NOTIFICATION_TIMEOUT + 500);
    }
};
