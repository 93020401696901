import { Lightning, Language, Registry } from '@lightningjs/sdk';
import SettingsButton from './SettingsButton';
import { COLORS, FONT_FACE } from '../../constants';
class RadioButton extends Lightning.Component {
    static _template() {
        return {
            Title: {
                x: 0,
                text: {
                    fontSize: 35,
                    fontFace: FONT_FACE.bold,
                },
            },
            ButtonHolder: {
                y: 80,
                //h: 200,
                BG: {
                    //h: 82,
                    transitions: {
                        color: { duration: 3, timingFunction: 'easeInOut' },
                    },
                },
                ToggleHolder: {
                    w: 41,
                    h: 41,
                    x: 2,
                    y: 6,
                    Toggle: {
                        mountY: 0,
                        mountX: 0,
                        transitions: {
                            x: { duration: 3, timingFunction: 'easeInOut' },
                        },
                    },
                },
                Label: {
                    x: 130,
                    y: 5,
                    text: {
                        fontSize: 35,
                        fontFace: FONT_FACE.light,
                    },
                },
            },
            SaveButton: {
                flexItem: { marginTop: 100 },
                w: 455,
                h: 70,
                x: 920,
                y: 80,
                type: SettingsButton,
                radius: 0,
                fontSize: 30,
                fontFace: FONT_FACE.light,
                focusFontColor: COLORS.dark,
                unfocusFontColor: COLORS.white,
                focusBackGroundColor: COLORS.lightGray3,
                unfocusBackgroundColor: COLORS.black3,
                label: Language.translate('save_settings'),
                padding: 0,
            },
        };
    }
    constructor(stage) {
        super(stage);
        this.announce = [];
    }
    _init() {
        this._togglePositions = [2, 34];
        this._toggleColors = [COLORS.lightGray7, COLORS.blue2];
        this._textureParams = {
            [RadioButton.templateTags.BG]: RadioButton.UnfocusStates[RadioButton.templateTags.BG],
            [RadioButton.templateTags.TOGGLE]: RadioButton.UnfocusStates[RadioButton.templateTags.TOGGLE],
        };
        // Convert to number
        this._toggleState = this.value ? 1 : 0;
        this.setBGColorByTag(RadioButton.templateTags.BG);
        this.setBGColorByTag(RadioButton.templateTags.TOGGLE);
        this.setTogglePositionByState();
    }
    set Title(title) {
        this.patch({ Title: { text: { text: title } } });
        this.announce.push(title);
    }
    set Label(label) {
        this.patch({ ButtonHolder: { Label: { text: { text: label } } } });
        this.announce.push(label);
    }
    get BGColor() {
        return this._toggleColors[this._toggleState];
    }
    get togglePosition() {
        return this._togglePositions[this._toggleState];
    }
    setTogglePositionByState() {
        this.tag('Toggle').patch({ x: this.togglePosition });
    }
    setBGColorByTag(tag = RadioButton.templateTags.BG) {
        this.tag(tag).patch({
            texture: Lightning.Tools.getRoundRect(
            // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
            ...this._textureParams[tag], tag === RadioButton.templateTags.BG ? this.BGColor : COLORS.white),
        });
    }
    toggle() {
        this._toggleState = this._toggleState === 0 ? 1 : 0;
        this.setTogglePositionByState();
        this.setBGColorByTag();
        this._setState('ButtonHolder');
    }
    _handleEnter() {
        this._setState('ButtonHolder');
    }
    _toggleSaveText(txt = 'Save') {
        this.tag('SaveButton').patch({
            Label: {
                text: txt,
                transitions: {
                    text: { duration: 2, timingFunction: 'ease' },
                },
            },
        });
    }
    _handleRight() {
        this._setState('SaveButton');
    }
    _focus() {
        this._textureParams.BG = RadioButton.FocusStates[RadioButton.templateTags.BG];
        this.setBGColorByTag(RadioButton.templateTags.BG);
    }
    _unfocus() {
        this._textureParams.BG = RadioButton.UnfocusStates[RadioButton.templateTags.BG];
        this.setBGColorByTag(RadioButton.templateTags.BG);
    }
    static _states() {
        return [
            class ButtonHolder extends this {
                $enter({ prevState }) {
                    // Don't reset state when returning from Save
                    if (prevState !== 'SaveButton') {
                        this.toggle();
                        //Seems odd when it happens almost simultaneously with toggle changing
                        Registry.setTimeout(() => this._setState('SaveButton'), 200);
                    }
                    else {
                        this._getFocused();
                    }
                }
                _getFocused() {
                    //return this.tag('ButtonHolder')
                    return this;
                }
                _handleBack() {
                    this._setState('');
                }
            },
            class SaveButton extends this {
                _getFocused() {
                    return this.tag('SaveButton') || this;
                }
                _handleEnter() {
                    this.signal('onSave', this._toggleState);
                    this._toggleSaveText('Saved!');
                    Registry.setTimeout(() => {
                        this.tag('SaveButton')._unfocus();
                        this._toggleSaveText();
                    }, 200);
                }
                _handleLeft() {
                    this._setState('');
                }
                _handleBack() {
                    this._setState('ButtonHolder');
                }
            },
        ];
    }
}
RadioButton.templateTags = {
    BG: 'BG',
    TOGGLE: 'Toggle',
};
RadioButton.FocusStates = {
    [RadioButton.templateTags.BG]: [78, 50, 19, 1, COLORS.white, true],
    [RadioButton.templateTags.TOGGLE]: [38, 38, 19, 1, COLORS.white, true],
};
RadioButton.UnfocusStates = {
    [RadioButton.templateTags.BG]: [78, 50, 19, 1, COLORS.transparent, true],
    [RadioButton.templateTags.TOGGLE]: [38, 38, 19, 1, COLORS.white, true],
};
export default RadioButton;
